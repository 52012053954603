import { clearTimeouts, engine as anime } from "../index";
import { getTemplateDurationOveride } from "../../components/shared/utils/timeline";
import _ from "lodash";

const timeouts = [];

const main = (props) => {
  const timeouts = [];
  const { id, format, templateName, data } = props;

  const timeline = anime.timeline();
  const templateDuration = getTemplateDurationOveride({
    apiSetup: props?.apiSetup,
    tournamentId: props?.data?.event?.competitionId,
    fallback:
      _.find(props?.data?.data?.options, { id: "videoduration" })?.value || 0,
    templateName,
    event: data?.event,
  });

  $(`#${id} .ant-progress-bg`).css("width", "100%");
  $(`#${id} .ant-progress-bg`).css(
    "transition-duration",
    `${templateDuration}s`,
  );

  const dynamic = props?.data?.data?.dynamic;
  const matchInfo = _.get(dynamic, "matchInfo");
  const standing = _.get(dynamic, "standing");
  const homeTeam = matchInfo?.contestant?.[0];
  const awayTeam = matchInfo?.contestant?.[1];

  const findGroup = _.find(standing, (group) => {
    return (
      _.find(group?.ranking, { contestantId: homeTeam?.id }) ||
      _.find(group?.ranking, { contestantId: awayTeam?.id })
    );
  });
  let thirdRanking = [];
  if (findGroup?.ranking?.length) {
    thirdRanking = findGroup?.ranking?.slice(20, 30);
  }

  console.log('thirdRanking', thirdRanking)

  $(
    `#${id} .qatar-logo, #${id} .main-headline, #${id} .competition-logo-container, #${id} .player`,
  ).css("opacity", 0);

  anime({
    targets: [`#${id} .competition-logo-container`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [-1000, 0],
    opacity: [1, 1],
    delay: 200,
  });
  anime({
    targets: [`#${id} .main-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500,
  });
  anime({
    targets: [`#${id} .second-title`],
    duration: 800,
    easing: "easeOutQuint",
    scale: [0, 1],
    opacity: [1, 1],
    delay: 500,
  });

  anime({
    targets: [`#${id} .qatar-logo`],
    duration: 800,
    easing: "easeOutQuint",
    translateX: [1000, 0],
    opacity: [1, 1],
    delay: 400,
  });

  anime({
    targets: [`#${id} .page-1 .group-table:nth-child(1) .group-content`],
    duration: 1000,
    easing: "easeInQuart",
    opacity: [0, 1],
    // translateX: [-2000, 0],
    delay: 500,
  });

  anime({
    targets: [`#${id} .page-1 .group-table:nth-child(2) .group-content`],
    duration: 1000,
    easing: "easeInQuart",
    opacity: [0, 1],
    // translateX: [2000, 0],
    delay: 800,
  });

  if (thirdRanking?.length) {
    const timeout1 = setTimeout(() => {
      anime({
        targets: [`#${id} .page-1`],
        duration: 1000,
        easing: "easeOutQuint",
        // translateX: [0, 1400],
        // rotateZ: [22, 22],
        opacity: [1, 0],
      });

      anime({
        targets: [`#${id} .page-2 .group-table:nth-child(1) .group-content`],
        duration: 1500,
        easing: "easeInOutQuart",
        opacity: [0, 1],
        // translateX: [-2000, 0],
        delay: 500,
      });

      anime({
        targets: [`#${id} .page-2 .group-table:nth-child(2) .group-content`],
        duration: 1500,
        easing: "easeInOutQuart",
        opacity: [0, 1],
        // translateX: [2000, 0],
        delay: 800,
      });
    }, 7500);

    timeouts.push(timeout1);
  }

  return {
    timeline: [],
    timeouts,
  };
};

//clean up function, unmounting
export const animationCleanUp = (animationReturn) => {
  const { timeouts } = animationReturn;
  clearTimeouts(timeouts);
};

export default main;
