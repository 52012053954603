const main = {
  apiUrl: "https://api.womansworldcup.spovizz.com",
  frontendUrl: "https://app.womansworldcup.spovizz.com/frontend",
  videoUrl: "https://video.spovizz.com",
  client: "womansworldcup_live",
};

export default {
  ...main,
  testMode: false,
  testData: {
    templateDuration: 4,
  },
  appName: "Spo|Vizz",
  apiUrl: main.apiUrl,
  templateDuration: 5000,
  videoBeginColor: "rgb(0, 0, 255)",
  videoEndColor: "rgb(0, 255, 0)",
  videoBeginColorDuration: 2000,
  videoEndColorDuration: 2000,
  timeline: {
    firstSlideDelay: 500,
    fadeInDuration: 100,
    fadeOutDuration: 500,
    defaultDuration: 15, //in secs, just a backup value
    transitionType: "video", //null
    videoTransitionBefore: 1300, //time when video covering whole screen, so we can start template switching
    videoTransitionAfter: 0, //delay until new template show up, after transition is finished
  },
  showOutroAfter: 12000,
  timelineAutoLinkEnabled: true,
  timelineLinkAutomation: {
    "PRE-MATCH": {
      beforeInMinutes: 40,
    },
    "LIVE-MATCH": {
      event: "start_game",
    },
    "POST-MATCH": {
      afterGameEnd: 5,
    },
  },
  reloadOnTimelineError: true,
  flagsCompetitions: {
    competitionIds: [
      "8tddm56zbasf57jkkay4kbf11",
      "cesdwwnxbc5fmajgroc0hqzy2",
      "45db8orh1qttbsqq9hqapmbit",
      "8x62utr2uti3i7kk14isbnip6",
      "595nsvo7ykvoe690b1e4u5n56",
    ],
    tournamentIds: [
      "4lp7vq583c95jwjhaohqbl9g4",
      "6g6a8weru6ytaa0mr0hpwn9xw",
      "5te8vskbhb3t1n687hua2waac",
      "5mqc55tieqhbz2sech0fp70bu",
      "3r70u3fqh4hthsa2j4hsefis",
    ],
  },
};
