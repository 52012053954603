import React, { useEffect } from "react";

import getContentById from "../../../../shared/utils/getContentById";
import _ from "lodash";
import Backgrounds from "../../_partials/Backgrounds/Index";
import TemplateBlock from "../../_partials/TemplateBlock/Index";
import prepareVideoBg from "../../../../shared/functions/prepareVideoBg";
import animationScript, {
  animationCleanUp,
} from "../../../../../animations/worldcup/lineup";

import assets from "./assets";
import getOptaCountryCode from "../../../../shared/utils/getOptaCountryCode";
import countries from "../../../../../language/de/countries.json";
import { useTranslation } from "react-i18next";
// import outroVideo9x16 from "../../../assets/outros/outro_playoffs_9x16.webm";
import Image from "../../../../shared/components/Image";
import {
  getDynamicLogoPath,
  toIsoDateString,
} from "../../../../shared/utils/functions";
import { getShortPlayerName } from "../../../../shared/feeds/worldcup/playerHelper";
import { WcBgCircle } from "../../../../shared/backgrounds/WcBgCircle/Index";
import ProgressBar from "../../../../shared/components/ProgressBar";
import { MatchScoreWidget } from "../../_partials/MatchScoreWidget/Index";

require("./style/style.scss");
require("./style/animation.scss");

const translations = {
  Group: "Gruppe",
};

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    isTimeline,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;
  const { t } = useTranslation();

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
          apiSetup,
          templateName: data?.template_name,
        });
      }
    }

    //handle outro video BEGIN
    // if(mode === "video" && !isTimeline) {
    //   const outroVideo = $(`#${id} video.outro-optional-video`);
    //   outroVideo.get(0).addEventListener("loadedmetadata", function() {
    //       const that = this;
    //       setTimeout(()=> {
    //         outroVideo?.css('opacity', 1);
    //         this.currentTime = 0;
    //         that.play();
    //       }, frontendCfg.showOutroAfter)
    //     }, false);
    // }
    //handle outro video END

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
      }
    };
  }, [animation, mode]);

  const templateData = _.get(data, "data");

  const { options, fields, dynamic } = templateData;
  const color1 = getContentById("color1", options);
  const color2 = getContentById("color2", options);

  const headline = getContentById("headline", fields);

  const teamHome = _.get(dynamic, "assets.teamHome");
  const teamAway = _.get(dynamic, "assets.teamAway");
  // const nextGame = _.get(dynamic, "nextGame");
  const matchInfo = _.get(dynamic, "matchInfo");
  const lineups = _.get(dynamic, "lineup");
  // const teamDataStyles = _.get(teamData, "styles", {});

  const competitionId = matchInfo?.competition?.id;

  const thisGameDate = new Date(
    toIsoDateString(matchInfo?.date, matchInfo?.time),
  );

  const backgroundImage = getMainBackground(format, _.get(dynamic, "teamSide"));

  const homeTeam = _.find(matchInfo?.contestant, { position: "home" });
  const awayTeam = _.find(matchInfo?.contestant, { position: "away" });

  const teamSide = getContentById("selectedSide", fields);

  const teamAlpha2 =
    teamSide === "home"
      ? getOptaCountryCode(homeTeam?.name, homeTeam?.shortName, homeTeam?.code)
      : getOptaCountryCode(awayTeam?.name, awayTeam?.shortName, awayTeam?.code);
  const teamId = teamSide === "home" ? homeTeam?.id : awayTeam?.id;
  const lineupData =
    teamSide === "home"
      ? _.find(lineups, { contestantId: homeTeam?.id })
      : _.find(lineups, { contestantId: awayTeam?.id });
  const formationUsed = lineupData?.formationUsed;
  const firstTeamOfficial = _.isArray(lineupData?.teamOfficial) ? lineupData?.teamOfficial?.[0] : lineupData?.teamOfficial;
  const coach = firstTeamOfficial?.firstName ? (
    <div>
      {firstTeamOfficial?.shortFirstName}{" "}
      {firstTeamOfficial?.shortLastName}
    </div>
  ) : (
    "-"
  );

  const scores = _.get(dynamic, "liveData.matchDetails.scores");

  const matchDetails = _.get(dynamic, "liveData.matchDetails");
  const matchStatus = _.get(matchDetails, "matchStatus");

  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""} side-${_.get(dynamic, "teamSide")}`}
    >
      <TemplateBlock>
        <Backgrounds bg1={backgroundImage} loop={true} />
        {/*<WcBgCircle />*/}

        <div className={"wc-content wc-content-v2"}>
          <ProgressBar height={"8px"} />
          <div className={"wc-bg-content"}></div>

          <div className={"qatar-logo"}>
            <img src={assets.insight_zone_logo} />
          </div>

          <div className={"left-block-side"}>
            <div className={"teams-block " + teamSide}>
              {teamSide === "home" ? (
                <div className={"team-logo-container home"}>
                  <Image
                    className="team-logo"
                    src={getDynamicLogoPath({ team: homeTeam, competitionId })}
                  />
                  <div className={"team-name home"}>
                    {t(homeTeam?.shortName || homeTeam?.name, {
                      ns: "countries",
                    })}
                  </div>
                </div>
              ) : (
                <div className={"team-logo-container away"}>
                  <Image
                    className="team-logo"
                    src={getDynamicLogoPath({ team: awayTeam, competitionId })}
                  />

                  <div className={"team-name away"}>
                    {t(awayTeam?.shortName || awayTeam?.name, {
                      ns: "countries",
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={"group"}>
            {matchInfo?.series?.name.replace(
              "Group",
              t("Group", { keyPrefix: "templates" }),
            ) || ""}
          </div>
          <MatchScoreWidget
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            scores={scores}
            matchStatus={matchStatus}
            matchDetails={matchDetails}
            competitionId={competitionId}
            matchInfo={matchInfo}
          />

          <div className={"coach-info"}>
            <div className={"group"}>
              {t("Coach", { keyPrefix: "templates" })}
            </div>
            <div className={"vs-team-info"}>{coach}</div>
          </div>

          <div className={"right-block-side"}>
            <div className={"wc-headline green-glow"}>
              {t("Lineup", { keyPrefix: "templates" })}
            </div>

            <div className={"lineup"}>
              <div className={"lineup-content"}>
                {lineupData?.player
                  .filter((p) => p.position !== "Substitute")
                  .map((p) => {
                    return (
                      <div key={`l${p.playerId}`} className={"player"}>
                        <div className={"shirt-number"}>{p.shirtNumber}</div>
                        <div className={"player-name"}>
                          {p?.shortFirstName?.[0]}.{" "}
                          {getShortPlayerName(p, false, true)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className={"lineup lineup-reserve"}>
              <div className={"lineup-content"}>
                {lineupData?.player
                  .filter((p) => p.position === "Substitute")
                  .map((p) => {
                    return (
                      <div key={`l${p.playerId}`} className={"player"}>
                        <div className={"shirt-number"}>{p.shirtNumber}</div>
                        <div className={"player-name"}>
                          {p?.shortFirstName?.[0]}.{" "}
                          {getShortPlayerName(p, false, true)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </TemplateBlock>
    </div>
  );
};

const getMainBackground = (format, side) => {
  return _.get(assets, `background_1920`);
};

export default Index;
