// return index to mark on standing table
// Example output:
//   [
//     {"rowIndex": 5, "rankStatus": "UEFA Europa League"},
//     {"rowIndex": 6, "rankStatus": "UEFA Conference League Qualifiers"},
//     {"rowIndex": 16, "rankStatus": "Relegation Play-off"},
//     {"rowIndex": 17, "rankStatus": "Relegation"}
//   ]
export const findRankStatusChanges = (ranking, statusesToSkip = []) => {
  const changes = [];
  //
  // console.log('ranking', ranking)

  if (ranking.length === 0) return changes;

  let lastIndexOfFirstRankStatus = -1;
  const firstRankStatus = ranking[0]?.rankStatus || "";

  for (let i = 0; i < ranking.length - 1; i++) {
    const currentRankStatus = ranking[i]?.rankStatus || "";
    const nextRankStatus = ranking[i + 1]?.rankStatus || "";

    if (currentRankStatus === firstRankStatus) {
      lastIndexOfFirstRankStatus = i + 1;
    }

    if (
      currentRankStatus !== nextRankStatus &&
      nextRankStatus &&
      !statusesToSkip.includes(nextRankStatus)
    ) {
      changes.push({
        rowIndex: i + 2,
        rankStatus: nextRankStatus,
        redLine: nextRankStatus === "Relegation Play-off",
        relegation: nextRankStatus.includes("Relegation"),
      });
    }
  }

  if (
    firstRankStatus &&
    !statusesToSkip.includes(firstRankStatus) &&
    lastIndexOfFirstRankStatus !== -1
  ) {
    changes.push({
      rowIndex: lastIndexOfFirstRankStatus,
      rankStatus: firstRankStatus,
      redLine: firstRankStatus === "Relegation Play-off",
      relegation: firstRankStatus.includes("Relegation"),
    });
  }

  changes.sort((a, b) => a.rowIndex - b.rowIndex);

  return changes;
};


export const findRankStatusChangesV2 = (ranking, statusesToSkip = []) => {
  const changes = [];
  //
  // console.log('ranking', ranking)
  //

  if (ranking.length === 0) return changes;

  let currentRankStatus = ranking[0]?.rankStatus || "";
  let startIndex = 0;

  for (let i = 1; i < ranking.length; i++) {
    const nextRankStatus = ranking[i]?.rankStatus || "";

    // Skip empty rankStatus
    if (currentRankStatus === "") {
      currentRankStatus = nextRankStatus;
      startIndex = i;
      continue;
    }

    if (currentRankStatus !== nextRankStatus) {
      if (!statusesToSkip.includes(currentRankStatus)) {
        changes.push({
          rowIndex: currentRankStatus.includes("Relegation") ? startIndex + 1 : i,
          rankStatus: currentRankStatus,
          redLine: currentRankStatus.includes("Relegation"),
          relegation: currentRankStatus.includes("Relegation"),
        });
      }
      currentRankStatus = nextRankStatus;
      startIndex = i;
    }
  }

  // Add the last segment, ensuring it's not empty and not in statusesToSkip
  if (currentRankStatus !== "" && !statusesToSkip.includes(currentRankStatus)) {
    changes.push({
      rowIndex: currentRankStatus.includes("Relegation") ? startIndex + 1 : ranking.length,
      rankStatus: currentRankStatus,
      redLine: currentRankStatus.includes("Relegation"),
      relegation: currentRankStatus.includes("Relegation"),
    });
  }

  changes.sort((a, b) => a.rowIndex - b.rowIndex);

  return changes;
};


