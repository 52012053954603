import { useTranslation } from "react-i18next";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "socket.io-client";
import {
  apiGetClosestMatch,
  getTemplateById,
} from "../../../../services/backend/api.service";
import _ from "lodash";

import { fadeIn, fadeOut } from "../../../../animations/timeline";

import FrontendConfig from "../../../../config/frontend";
import frontendConfig from "../../../../config/frontend";
import BackendConfig from "../../../../config/backend";

import { templates as templateComponents } from "../../../frontend/pages/Template/index";
import preloadImages from "../../functions/preloadImages";
import getImagesToPreload from "../../functions/getImagesToPreload";
import { useGlobalFrontendStore } from "../../../../stores/globalFrontendStore";

import { asyncTimeout, sleepFn } from "../../functions/timeout";

import "./styles.scss";
import VideoModeWrapper from "../../../shared/components/VideoModeWrapper/Index";
import { useIsMounted } from "../../../../hooks";
// import timelineMainBg from "../../../../assets/timeline/timeline_season_2023.jpg";
import worldcupTransition from "../../../frontend/assets/wc/videos/wipe_v2.webm";
import {
  getCurrentTimelineLink,
  getCurrentTimelineLinkAdler,
} from "../../utils/opta/timeline";
// import timelineMainBg from "../../../../assets/timeline/timeline_season_2023.jpg";
// import timelineMainBg from "../../../../assets/timeline/timeline_playoffs_2023.jpg";
import timelineMainBg from "../../../../assets/timeline/timeline_adler_2023.png";
import backendConfig from "../../../../config/backend";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import TimelineLiveIndicator from "../../../frontend/templates/_partials/TimelineLiveIndicator/Index";
import { getTemplateDurationOveride } from "../../utils/timeline";
import { Button } from "antd";
import AlarmTemplate from "./alarmTemplate";
import { useWebsocket } from "../../contexts/websocket";
import { axios } from "../../../../services/axios";

const templateTestSwitch = 3000;
const REFRESH_TIMELINE_EVERY_X_TEMPLATES = 4;
let timelineStats = {};

const waitingKeypress = () => {
  return new Promise((resolve) => {
    document.addEventListener("keydown", onKeyHandler);

    function onKeyHandler(e) {
      //spacebar
      if (e.keyCode === 32) {
        document.removeEventListener("keydown", onKeyHandler);
        resolve();
      }
    }
  });
};

const Index = (props) => {
  const isMounted = useIsMounted();
  const timeoutRef = useRef();
  const alarmTimeoutRef = useRef();

  const [messageQueue, setMessageQueue] = useState([]); // State to hold the queue of messages
  const [isProcessing, setIsProcessing] = useState(false); // State to manage the processing status

  const { socket } = useWebsocket();

  const {
    previewFormat,
    timelineData,
    changeEmitter,
    mode,
    setRefreshTimeline,
    style,
  } = props;
  const apiSetup = useGlobalFrontendStore((state) => state.apiSetup);
  const setClosestMatch = useGlobalFrontendStore(
    (state) => state.setClosestMatch,
  );
  const setReloadTimeline = useGlobalFrontendStore(
    (state) => state.setReloadTimeline,
  );
  const closestMatch = useGlobalFrontendStore((state) => state.closestMatch);
  const timelineCurrentSlide = useGlobalFrontendStore(
    (state) => state.timelineCurrentSlide,
  );
  const timelineAction = useGlobalFrontendStore(
    (state) => state.timelineAction,
  );
  const setTimelineCurrentSlide = useGlobalFrontendStore(
    (state) => state.setTimelineCurrentSlide,
  );

  const onTimelineAction = (data) => {
    // if (typeof changeEmitter === "function" && isMounted.current) {
    if (typeof changeEmitter === "function") {
      const merged = _.merge(timelineStats, data);
      changeEmitter(merged);
    }
  };

  const { t } = useTranslation();

  const [imagesLoaded, setImagesLoaded] = useState(false);

  // const [currentAnimation, setCurrentAnimation] = useState(false);
  // const [nextAnimation, setNextAnimation] = useState(false);

  const [initialied, setInitialized] = useState(false);
  const [showTemplateVideo, setShowTemplateVideo] = useState(false);
  const [videoShowStart, setVideoShowStart] = useState(true);
  const [videoShowEnd, setVideoShowEnd] = useState(false);
  const [ready, setReady] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesData, setSlidesData] = useState([]);
  const [loopTimes, setLoopTimes] = useState(0);
  const [processed, setProcessed] = useState(0);
  const [errorState, setErrorState] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [showAlarm, setShowAlarm] = useState("");
  const [alarmData, setAlarmData] = useState(null);
  const [totalSlidesViewed, setTotalSlidesViewed] = useState(0);

  const search = useLocation()?.search;
  const searchParams = new URLSearchParams(search);

  const adsEnabled = searchParams.get("ads")?.toLowerCase() === "true";
  const xSlides = parseInt(searchParams?.get("x-slides") || 15, 10);
  const xDuration = parseInt(searchParams?.get("x-duration") || 63, 10);
  const queryLng = searchParams.get("lng") || "en";

  //GLOBAL SETUP
  // const timelinePlayMode = _.get(apiSetup, "timeline.settings.playMode", "auto");

  //timeline specific
  const timelinePlayMode = _.get(timelineData, "options.mode", "auto");
  const timelineTransition = _.get(
    timelineData,
    "options.transition",
    FrontendConfig.timeline.transitionType,
  );
  const fadeInDuration = FrontendConfig.timeline.fadeInDuration;

  const [activeSlot, setActiveSlot] = useState("current");
  const [slotCurrentData, setSlotCurrentData] = useState();
  const [slotNextData, setSlotNextData] = useState();

  const templates = _.get(timelineData, "templates", []);

  const [nextMode, setNextMode] = useState("static");
  const [currentMode, setCurrentMode] = useState("static");

  const currentData = _.find(slidesData, { currentIndex });
  const nextData =
    templates.length === currentIndex + 1
      ? _.find(slidesData, { currentIndex: 0 })
      : _.find(slidesData, { currentIndex: currentIndex + 1 });
  const transitionVideo = $(`.timeline-transition-video`);
  const videoDom = transitionVideo.get(0);

  const competitionId = timelineData?.event?.competitionId;

  const processQueue = useCallback(async () => {
    const [nextMessage, ...remainingQueue] = messageQueue;
    setMessageQueue(remainingQueue); // Remove the first message from the queue

    if (nextMessage) {
      await processMessage(nextMessage); // Process the message
    }

    //2 sec buffer between multiple messages
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsProcessing(false);
  }, [messageQueue]);

  //##############################
  // increment in loop - set 0, after last slide
  //##############################
  const incrementInLoop = (optionalIndex) => {
    setCurrentIndex((currentIndex) => {
      if (optionalIndex > -1) {
        return optionalIndex;
      }

      // set currentIndex to processed
      const incremented = processed;
      if (templates.length == incremented) {
        return 0;
      }

      return incremented;
    });
  };

  const getNextIndex = (current) => {
    if (current + 1 === templates.length) {
      return 0;
    }
    return current + 1;
  };

  const goToPageIndex = async (index) => {
    if (index < 0) return;
    if (index >= templates.length) {
      index = 0;
    }
    if (timeoutRef.current) {
      timeoutRef.current.clear();
    }
    // if (alarmTimeoutRef.current) {
    //   alarmTimeoutRef.current.clear();
    // }
    setSlotNextData(null);
    await asyncTimeout(100);

    const nextId = _.get(templates, `${index}.templateId`);
    const nextTemplate = _.get(templates, `${index}.template`);
    const preloadNext = await getTemplateById({
      id: nextId,
      template_name: nextTemplate,
    }).catch((err) => {
      console.error(err);
    });
    const preloadNextData = _.get(preloadNext, "data", null);

    if (!preloadNextData) {
      setErrorState(true);
    }

    setSlidesData((slidesData) => {
      const newSlidesData = [
        ...slidesData.filter(
          (item) =>
            item.currentIndex === currentIndex || item.currentIndex === index,
        ),
        {
          currentIndex: index,
          ...preloadNextData,
        },
      ];

      if (newSlidesData.length > 3) {
        newSlidesData.shift();
      }

      return newSlidesData;
    });

    setCurrentMode("static");
    setNextMode("static");
    setSlotNextData(preloadNextData);
    fadeOut({
      slide: "current",
      duration: timelineTransition === "video" ? 0 : 1000,
    });
    setProcessed(index);
    setCurrentIndex(index);
    if (currentIndex === index) {
      // Click on same template again
      setRefresh((refresh) => {
        return !refresh;
      });
    }
    setActiveSlot("next");
    fadeIn({ slide: "next", duration: 1000 });
  };

  useEffect(() => {
    if (timelineCurrentSlide !== null) {
      const index = timelineCurrentSlide.index;
      const asyncCall = async () => {
        goToPageIndex(index);
      };
      asyncCall();
    }
  }, [timelineCurrentSlide]);

  useEffect(() => {
    const action = timelineAction?.action;
    if (action) {
      if (action === "next") {
        goToPageIndex(currentIndex + 1);
      } else {
        goToPageIndex(currentIndex - 1);
      }
    }
  }, [timelineAction]);

  useEffect(() => {
    if (errorState) {
      throw new Error("Error state, trigger....", errorState);
    }

    return () => {
      setErrorState(false);
    };
  }, [errorState]);

  useEffect(() => {
    const asyncCall = async () => {
      const timelineAutoLinkEnabled = _.get(
        apiSetup,
        "timeline.settings.timelineAutoLinkEnabled",
        false,
      );

      if (currentIndex === 0 && timelineAutoLinkEnabled) {
        const closestMatchApi = await apiGetClosestMatch({
          competitionId,
        }).catch((err) => {
          console.error("Timeline closestMatch err", err);
          setReloadTimeline(true);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        });

        if (closestMatchApi?.data) {
          setClosestMatch(closestMatchApi?.data);
        }
      }

      if (
        (currentIndex + 1) % REFRESH_TIMELINE_EVERY_X_TEMPLATES === 0 &&
        templates.length !== currentIndex + 1
      ) {
        setRefreshTimeline(true);
      }
    };
    asyncCall();
  }, [currentIndex]);

  // useEffect(() => {
  //   if (ready && loopTimes > 0 && (!slotCurrentData || !slotNextData)) {
  //     console.error("slotCurrentData EMPTY", slotCurrentData);
  //     console.error("slotNextData EMPTY", slotNextData);
  //   }
  // }, [slotCurrentData, slotNextData, ready, loopTimes]);

  useEffect(() => {
    if (templates?.length === 0) {
      console.warn("TIMELINE_PREVIEW:templates empty", {
        name: timelineData?.name,
        uuid: timelineData?.uuid,
        format: timelineData?.format,
        templates,
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      throw new Error("TIMELINE_PREVIEW:templates empty");
    }
  }, [templates]);

  useEffect(() => {
    const timelineAutoLinkEnabled = _.get(
      apiSetup,
      "timeline.settings.timelineAutoLinkEnabled",
      false,
    );
    const timelineLinks = closestMatch?.matchdayTimelines || [];

    let skipLogic = true;
    const anyTargetLinkUuid = timelineLinks
      .map((entry) => entry.uuid)
      ?.forEach((check) => {
        if (window.location.href.indexOf(check) > -1) {
          skipLogic = false;
        }
      });

    if (
      closestMatch?.closestMatch &&
      currentIndex === 0 &&
      timelineAutoLinkEnabled &&
      !skipLogic
    ) {
      let timelineLink =
        backendConfig?.clientBodyClass === "app-adler"
          ? getCurrentTimelineLinkAdler(closestMatch)
          : getCurrentTimelineLink(closestMatch);

      if (timelineData?.name?.includes("PRE-LIVE-POST-MATCHDAY")) {
        timelineLink = null;
      }

      if (timelineLink?.link) {
        const currentUrl = new URL(window.location.href);
        const targetUrl = new URL(timelineLink.link);

        //preserve query lang param
        currentUrl.searchParams.forEach((value, key) => {
          targetUrl.searchParams.set(key, value);
        });

        if (
          timelineLink?.link &&
          window.location.href.indexOf(timelineLink.uuid) === -1
        ) {
          // Check if the current URL is different from the target URL
          if (window.location.href !== targetUrl) {
            window.location.replace(targetUrl);
          }
        }
      }
    }
  }, [closestMatch, currentIndex]);

  //##############################
  // preload first two slides data
  //##############################
  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    const asyncCall = async () => {
      if (!templates.length) {
        return [];
      }

      //preload first two slides data
      const preload1 = await getTemplateById({
        id: templates[currentIndex]?.templateId,
        template_name: templates[currentIndex]?.template,
      }).catch((err) => {
        console.error("preload1", err);
      });

      let preload2 = null;
      let preload2Data = null;
      if (currentIndex + 1 < templates.length) {
        preload2 = await getTemplateById({
          id: templates[currentIndex + 1]?.templateId,
          template_name: templates[currentIndex + 1]?.template,
        }).catch((err) => {
          console.error("preload2", err);
        });

        preload2Data = _.get(preload2, "data", null);

        if (!preload2Data) {
          setErrorState(true);
          return;
        }
      }

      const preload1Data = _.get(preload1, "data", null);

      if (!preload1Data) {
        setErrorState(true);
        return;
      }

      setSlidesData((slidesData) => {
        setSlotCurrentData(preload1Data);
        setSlotNextData(preload2Data);

        return [
          {
            currentIndex: currentIndex,
            ...preload1Data,
          },
          {
            currentIndex: currentIndex + 1,
            ...preload2Data,
          },
        ];
      });
    };
    asyncCall();
  }, []);

  //##############################
  // preload next slide in advance, so we are always one ahead
  //##############################
  useEffect(() => {
    if (!templates.length) {
      return;
    }

    const asyncCall = async () => {
      const nextIndex = getNextIndex(currentIndex);
      const nextId = _.get(templates, `${nextIndex}.templateId`);
      const nextTemplate = _.get(templates, `${nextIndex}.template`);
      const preloadNext = await getTemplateById({
        id: nextId,
        template_name: nextTemplate,
      }).catch((err) => {
        console.error(err);
      });

      const preloadNextData = _.get(preloadNext, "data", null);

      if (!preloadNextData) {
        setErrorState(true);
      }

      setSlidesData((slidesData) => {
        const newSlidesData = [
          ...slidesData.filter((item) => item.currentIndex === currentIndex),
          {
            currentIndex: nextIndex,
            ...preloadNextData,
          },
        ];

        if (newSlidesData.length > 3) {
          newSlidesData.shift();
        }

        return newSlidesData;
      });

      if (currentIndex + 1 === templates.length) {
        onTimelineAction({
          ...timelineStats,
          totalLoops: loopTimes + 1,
        });

        setLoopTimes((times) => times + 1);

        setRefreshTimeline(true);
      }
    };

    asyncCall();
  }, [refresh, currentIndex, templates.length]);

  //##############################
  // init ready: images loaded, slidesData ready
  //##############################
  useEffect(() => {
    if (slidesData && !ready && imagesLoaded) {
      if (mode === "video") {
      } else {
        setReady(true);
      }
    }
  }, [slidesData, imagesLoaded]);

  //##############################
  // process templates
  //##############################
  useEffect(() => {
    const asyncCall = async () => {
      if (ready && processed <= currentIndex && currentData && nextData) {
        const options = _.get(currentData, "data.options", []);
        const initialTimeout = FrontendConfig.timeline.firstSlideDelay;
        const fadeOutDuration = FrontendConfig.timeline.fadeOutDuration;
        const videoTransitionBefore =
          FrontendConfig.timeline.videoTransitionBefore;
        const videoTransitionAfter =
          FrontendConfig.timeline.videoTransitionAfter;
        let templateDuration = _.get(
          _.find(options, { id: "videoduration" }),
          "value",
          FrontendConfig.timeline.defaultDuration,
        );

        const templateTimeOverride = getTemplateDurationOveride({
          apiSetup,
          tournamentId: competitionId,
          fallback: templateDuration,
          templateName: currentData?.template_name,
          event: currentData?.event,
        });

        templateDuration = parseInt(templateTimeOverride);

        if (FrontendConfig.testMode) {
          templateDuration = FrontendConfig.testData.templateDuration;
        }
        onTimelineAction({
          currentSlide: currentIndex + 1,
          totalSlides: templates.length,
          currentTemplate: _.get(currentData, "template_name"),
          currentDuration: templateDuration,
          nextTemplate: _.get(nextData, "template_name"),
        });

        if (currentIndex === 0 && loopTimes === 0) {
          if (timelinePlayMode === "manual" && mode !== "video") {
            await waitingKeypress();
            // $(document).trigger("TIMELINE_START_VIDEOBG")
          } else {
            await asyncTimeout(initialTimeout);
          }
        }

        if (FrontendConfig.testMode) {
          console.time("TEMPLATE SLIDE TIME");
        }

        onTimelineAction({
          state: "TIMELINE_START",
        });

        if (isMounted.current) {
          if (activeSlot == "current") {
            setCurrentMode("animation");
            setNextMode("static");
          } else {
            setNextMode("animation");
            setCurrentMode("static");
          }
        }

        //TEMPLATE FADEIN
        fadeIn({ slide: activeSlot, duration: fadeInDuration });
        //TEMPLATE FADEIN

        //hotfix for staticmedia video play trigger
        if (currentData?.template_name === "staticmedia") {
          const activeContainer =
            activeSlot === "current" ? ".active-slide" : ".next-slide";
          const videoBg = $(`${activeContainer} video.custom-background`).get(
            0,
          );

          if (videoBg) {
            videoBg.currentTime = 0;
            setTimeout(() => {
              videoBg.play();
            }, fadeInDuration / 2);
          }
        }

        onTimelineAction({
          state: "TEMPLATE_FADE_IN",
        });

        if (isMounted.current) {
          if (activeSlot == "current") {
            setSlotNextData(null);
            sleepFn(1000, setSlotNextData, nextData);
          } else {
            setSlotCurrentData(null);
            sleepFn(1000, setSlotCurrentData, nextData);
          }
        }

        if (isMounted.current) {
          //TEMPLATE SHOWCASE
          if (timelinePlayMode === "manual" && mode !== "video") {
            await waitingKeypress();
          } else {
            if (timeoutRef.current) {
              timeoutRef.current.clear();
            }
            // if (alarmTimeoutRef.current) {
            //   alarmTimeoutRef.current.clear();
            // }
            const preciseDuration =
              timelineTransition === "video"
                ? templateDuration * 1000 -
                  FrontendConfig.timeline.videoTransitionBefore
                : templateDuration * 1000;
            timeoutRef.current = asyncTimeout(preciseDuration);
            await timeoutRef.current;
          }
          //TEMPLATE SHOWCASE
        }

        // onTimelineAction({
        //     state: "START_FADEOUT"
        // })

        if (isMounted.current) {
          if (timelineTransition === "video") {
            videoDom.currentTime = 0;
            videoDom.play();
            await asyncTimeout(videoTransitionBefore);
          }
          //TEMPLATE FADEOUT
          fadeOut({
            slide: activeSlot,
            duration: timelineTransition === "video" ? 0 : fadeOutDuration,
          });
          await asyncTimeout(
            timelineTransition === "video"
              ? videoTransitionAfter
              : fadeOutDuration,
          );
          //TEMPLATE FADEOUT

          if (timelinePlayMode === "manual" && mode !== "video") {
            await waitingKeypress();
          }

          if (FrontendConfig.testMode) {
            console.timeEnd("TEMPLATE SLIDE TIME");
          }

          if (activeSlot == "current") {
            setActiveSlot("next");
          } else {
            setActiveSlot("current");
          }
        }

        if (isMounted.current) {
          //last slide
          if (currentIndex === templates.length - 1) {
            if (mode === "video") {
              setVideoShowEnd(true);
              setShowTemplateVideo(false);
            }

            onTimelineAction({
              state: "TIMELINE_END",
            });
          }

          onTimelineAction({
            state: "END_SLIDE",
          });

          if (templates.length === currentIndex + 1) {
            setProcessed(0);
            setCurrentIndex(0);
          } else {
            if (processed < templates.length) {
              setProcessed((processed) => {
                return processed + 1;
              });
            }
          }
        }
      }
    };
    asyncCall();
  }, [
    ready,
    refresh,
    currentIndex,
    _.get(currentData, "updatedAt"),
    _.get(nextData, "updatedAt"),
  ]);

  const TemplateComponent =
    templateComponents[_.get(slotCurrentData, "template_name")];
  const TemplateComponentNext =
    templateComponents[_.get(slotNextData, "template_name")];

  const timelineBackground = `${BackendConfig.apiUrl}${_.get(apiSetup, "design.timelineBackground.url")}`;

  const Images = getImagesToPreload(_.get(currentData, "data", []));

  useEffect(() => {
    if (!socket) {
      return;
    }
    if (!socket.connected) {
      socket.connect();
    }

    // return () => {
    //   if (socket) {
    //     console.log("Cleaning up socket connection.");
    //     socket.off("connect");
    //     socket.off("disconnect");
    //   }
    // };
  }, [socket]);

  const processMessage = async (data) => {
    await onShowAlarm(data);
  };

  const handleAlarmEvent = useCallback(
    (data) => {
      const { target } = data;
      if (target?.type === "timeline") {
        const uuids = target?.uuid;
        if (uuids.includes(timelineData.uuid)) {
          setMessageQueue((prevQueue) => [...prevQueue, data]);
        }
      }
    },
    [currentIndex, timelineBackground],
  );

  useEffect(() => {
    if (!isProcessing && messageQueue.length > 0) {
      setIsProcessing(true);
      processQueue();
    }
  }, [messageQueue, isProcessing]);

  const onShowAlarm = async (data) => {
    setAlarmData(data);
    setShowAlarm("start");
    alarmTimeoutRef.current = asyncTimeout(30_000);
    await alarmTimeoutRef.current;
    setShowAlarm("end");
    alarmTimeoutRef.current = asyncTimeout(2000);
    await alarmTimeoutRef.current;
    setShowAlarm("");
    setAlarmData(null);
  };

  useEffect(() => {
    if (socket != null) {
      socket.on("notify_alarm", handleAlarmEvent);
    }
    return () => {
      if (socket) {
        socket.off("notify_alarm", handleAlarmEvent);
      }
    };
  }, [handleAlarmEvent, socket]);

  useEffect(() => {
    const asyncCall = async () => {
      if (Images.length) {
        const promises = preloadImages(Images);
        await Promise.all([promises]);
        setImagesLoaded(true);
      } else {
        setImagesLoaded(true);
      }
    };
    asyncCall();
  }, [Images]);

  useEffect(() => {
    if (processed > 0 && processed <= templates.length) {
      incrementInLoop();
    }
  }, [refresh, processed]);

  useEffect(() => {
    const asyncCall = async () => {
      if (mode === "video" && imagesLoaded) {
        await asyncTimeout(FrontendConfig.videoBeginColorDuration);
        setShowTemplateVideo(true);
        setReady(true);
        setVideoShowStart(false);
      }
    };
    asyncCall();
  }, [imagesLoaded]);

  //ADS TRIGGER HANDLER - SCTE.35 MARKERS
  useEffect(() => {
    if (adsEnabled) {
      setTotalSlidesViewed((prev) => {
        const newTotal = prev + 1;

        if (newTotal % xSlides === 0) {
          const asyncCall = async () => {
            const data = {
              lng: queryLng,
              timelineUuid: timelineData?.uuid,
              duration: xDuration,
              testMode: false,
              currentIndex,
              newTotal,
              xSlides,
            };

            await axios({
              url: `/ads/timeline`,
              method: "post",
              timeout: 30 * 1000,
              data: data,
            }).catch((err) => {
              console.error("timeline ads trigger handler", err);
            });
          };
          asyncCall();
        }

        return newTotal;
      });
    }
  }, [currentIndex, adsEnabled]);

  if (mode === "video") {
    return (
      <React.Fragment>
        <VideoModeWrapper showStart={videoShowStart} showEnd={videoShowEnd} />
        <div className={"timeline-container video-mode"}>
          <img
            className={"timeline-main-bg"}
            src={
              BackendConfig.clientBodyClass === "app-opta"
                ? null
                : timelineBackground || timelineMainBg
            }
          />
          <video
            className={"timeline-transition-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
            preload={"auto"}
            poster={
              "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            }
          >
            <source src={worldcupTransition} />
          </video>
          {showTemplateVideo && (
            <>
              <div className={"template-container active-slide"}>
                {TemplateComponent && (
                  <TemplateComponent
                    {...props}
                    format={previewFormat}
                    images={Images}
                    apiSetup={apiSetup}
                    mode={currentMode}
                    data={slotCurrentData}
                    isTimeline={true}
                  />
                )}
              </div>

              <div className={"template-container next-slide"}>
                {TemplateComponentNext && (
                  <TemplateComponentNext
                    {...props}
                    format={previewFormat}
                    images={Images}
                    apiSetup={apiSetup}
                    mode={nextMode}
                    data={slotNextData}
                    isTimeline={true}
                  />
                )}
              </div>
            </>
          )}

          {/*<div className={"debug-Info"}>*/}
          {/*    currentIndex: {currentIndex} <br/>*/}
          {/*    currentData: {_.get(slidesData[currentIndex], "template_name")} {_.get(slidesData[currentIndex], "uuid")}*/}
          {/*    <br/>*/}
          {/*    slidesData: {slidesData.length}<br/>*/}
          {/*    slotCurrentData: {_.get(slotCurrentData, "uuid")}<br/>*/}
          {/*    slotNextData: {_.get(slotNextData, "uuid")}<br/>*/}
          {/*    activeSlot: {activeSlot}*/}
          {/*</div>*/}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <AlarmTemplate
        showAlarm={showAlarm}
        data={alarmData}
        format={previewFormat}
        // Template={TemplateComponent}
        mode={"animation"}
      >
        <div className={"timeline-container"}>
          <img
            className={"timeline-main-bg"}
            src={
              BackendConfig.clientBodyClass === "app-opta"
                ? null
                : timelineBackground || timelineMainBg
            }
          />
          <video
            className={"timeline-transition-video"}
            controls={false}
            autoPlay={false}
            muted={true}
            loop={false}
            preload={"auto"}
            poster={
              "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            }
          >
            <source src={worldcupTransition} />
          </video>

          {/*<AlarmTemplate*/}
          {/*  showAlarm={showAlarm}*/}
          {/*  data={alarmData}*/}
          {/*  format={previewFormat}*/}
          {/*  Template={TemplateComponentNext}*/}
          {/*  mode={nextMode}*/}
          {/*>*/}

          {!imagesLoaded ? null : (
            <>
              <div className={"template-container active-slide"}>
                {TemplateComponent && (
                  <TemplateComponent
                    {...props}
                    format={previewFormat}
                    images={Images}
                    apiSetup={apiSetup}
                    mode={currentMode}
                    animation={currentMode === "static" ? false : true}
                    data={slotCurrentData}
                    isTimeline={true}
                  />
                )}
              </div>

              <div className={"template-container next-slide"}>
                {TemplateComponentNext && (
                  <TemplateComponentNext
                    {...props}
                    format={previewFormat}
                    images={Images}
                    apiSetup={apiSetup}
                    mode={nextMode}
                    animation={nextMode === "static" ? false : true}
                    data={slotNextData}
                    isTimeline={true}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </AlarmTemplate>
    );
  }
};

export default Index;
