import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import getContentById from "../../../shared/utils/getContentById";
import _ from "lodash";
import TemplateBlock from "../_partials/TemplateBlock/Index";

import animationScript, {
  animationCleanUp,
} from "../../../../animations/staticmedia";
import parseMediaUrl from "../../../shared/functions/parseMediaUrl";

import assets from "../../templates/adler/Results/assets";
import prepareVideoBg from "../../../shared/functions/prepareVideoBg";
import ImageOrVideo from "../../../shared/components/ImageOrVideo/Index";
import { audioManager } from "../../../shared/utils/audio-manager";

require("./style/style.scss");
require("./style/animation.scss");

const Index = (props) => {
  const refs = {
    player: React.createRef(),
    team: React.createRef(),
  };

  const {
    animation,
    data,
    images: Images,
    apiSetup,
    mode,
    format,
    _searchParams,
  } = props;

  const id = `${_.get(data, "template_name", "")}_${_.get(data, "uuid", "")}`;

  const templateData = _.get(data, "data");
  const { options, fields, dynamic } = templateData;
  const eventData = _.get(dynamic, "game");

  const color1 = getContentById("color1", options);

  const customBackground = getContentById("background", fields);

  const actualTimeAlias = _.get(eventData, "actualTimeAlias");
  const actualTimeAliasClass = "actual-period-" + actualTimeAlias;

  const prepareAnimation = () => {
    prepareVideoBg({ id });
  };

  const isVideoOrImage = (src) => {
    if (!src) {
      return "image";
    }
  
    if (
      src.indexOf(".mp4") > -1 ||
      src.indexOf(".webm") > -1 ||
      src.indexOf(".ogv") > -1
    ) {
      return "video";
    }
  
    return "image";
  };

  useEffect(() => {
    let animationReturn = null;
    if (mode === "animation" || mode === "video") {
      if (animation) {
        prepareAnimation();
        animationReturn = animationScript({
          refs,
          data,
          animation,
          mode,
          id,
          format,
        });
        
        if (isVideoOrImage(parseMediaUrl(customBackground.url)) === "video") {
          //pause global audio
          audioManager.fadeOutAndPause();
        }
      }
    }

    if (mode === "screenshot") {
      const videoBg = $(`#${id} video.custom-background`).get(0);
      if (videoBg) {
        videoBg.addEventListener(
          "loadedmetadata",
          function () {
            this.currentTime = 3;
            this.pause();
          },
          false,
        );
      }
    }

    return function cleanUp() {
      if (animationReturn) {
        animationCleanUp(animationReturn);
        audioManager.fadeInAndPlay();
      }
    };
  }, [animation, mode]);


  return (
    <div
      id={id}
      className={`T_${_.get(data, "template_name", "")} ${animation ? "animation" : ""}`}
    >
      <TemplateBlock>
        <div
          className={"custom-background-container"}
          style={{ backgroundColor: color1 }}
        >
          <ImageOrVideo
            loop={true}
            className={"custom-background"}
            src={parseMediaUrl(customBackground.url)}
            searchParams={_searchParams}
            animation={animation}
          />
        </div>
      </TemplateBlock>
    </div>
  );
};

export default Index;
